import { invoke } from "@tauri-apps/api/core"
import { type Event, TauriEvent, listen } from "@tauri-apps/api/event"
import type { WebviewWindow } from "@tauri-apps/api/webviewWindow"
import { getCurrent } from "@tauri-apps/api/webviewWindow"
import { type } from "@tauri-apps/plugin-os"
import type { LimitRequest } from "~/models/polycache/LimitRequest"
import type { TermMatchingRequest } from "~/models/polycache/TermMatchingRequest"

interface State {
  window?: WebviewWindow
  isWindowMaximized: boolean
  platform?: "linux" | "windows" | "macos" | "ios" | "android"
  handlersInitialized: boolean
}
let tauriState: Ref<State>

function errorIfNotOnDesktop() {
  if (!isDesktop())
    throw new Error("Calling tauri methods on browser")
}

async function initTauriHandlers() {
  if (!isDesktop())
    return
  if (tauriState.value.handlersInitialized)
    return console.log("Tauri handlers already initialized, skipping")
  tauriState.value.handlersInitialized = true

  tauriState.value.platform = await type()
  const current: WebviewWindow = await getCurrent()
  if (!current)
    return console.error("No window")
  tauriState.value.window = current
  current.onResized(async (_event) => {
    const isMaximized = await current.isMaximized()
    if (isMaximized !== undefined)
      tauriState.value.isWindowMaximized = isMaximized
  })
}

async function minimizeWindow() {
  errorIfNotOnDesktop()
  const window = tauriState.value.window
  if (!window)
    return console.error("No window")
  window.minimize()
}

async function maximizeWindow() {
  errorIfNotOnDesktop()
  const window = tauriState.value.window
  if (!window)
    return console.error("No window")
  window.maximize()
}

async function unmaximizeWindow() {
  errorIfNotOnDesktop()
  const window = tauriState.value.window
  if (!window)
    return console.error("No window")
  window.unmaximize()
}

async function toggleFullscreenWindow() {
  errorIfNotOnDesktop()
  const window = tauriState.value.window
  if (!window)
    return console.error("No window")
  window.setFullscreen(!window.isFullscreen())
}

async function closeWindow() {
  errorIfNotOnDesktop()
  const window = tauriState.value.window
  if (!window)
    return console.error("No window")
  window.close()
}

async function login(request: LoginRequest) {
  errorIfNotOnDesktop()
  return invoke<LoginResponse>("login", { request })
}

async function signup(request: SignupRequest) {
  errorIfNotOnDesktop()
  return invoke<SignupResponse>("signup", { request })
}

async function verify(request: VerifyRequest) {
  errorIfNotOnDesktop()
  return invoke<LoginResponse>("verify", { request })
}

async function resendVerification(request: NewVerificationCodeRequest) {
  errorIfNotOnDesktop()
  return invoke<LoginResponse>("resend_verification", { request })
}

async function forgotPassword(request: ForgotPasswordRequest) {
  errorIfNotOnDesktop()
  return invoke<LoginResponse>("forgot_password", { request })
}

async function changePassword(request: ChangePasswordRequest) {
  errorIfNotOnDesktop()
  return invoke<LoginResponse>("change_password", { request })
}

async function getTagProperties(request: TagPropertyCreateRequest) {
  errorIfNotOnDesktop()
  return invoke<Record<string, FileProperty>>("get_tag_properties", {
    request,
  })
}

async function getRecentSearches(request: LimitRequest) {
  errorIfNotOnDesktop()
  return invoke<MatchingName[]>("get_recent_searches", { request })
}

async function getSearchTermMatching(request: TermMatchingRequest) {
  errorIfNotOnDesktop()
  return invoke<MatchingName[]>("get_search_term_matching", { request })
}

async function getTagsMatching(request: FileNameMatchingRequest) {
  errorIfNotOnDesktop()
  return invoke<MatchingName[]>("get_tags_matching", { request })
}

async function getFilesMatching(request: FileNameMatchingRequest) {
  errorIfNotOnDesktop()
  return invoke<MatchingFile[]>("get_files_matching", { request })
}

async function getFoldersMatching(request: FileNameMatchingRequest) {
  errorIfNotOnDesktop()
  return invoke<MatchingFile[]>("get_folders_matching", { request })
}

async function getChildrenMatching(request: FileNameMatchingRequest) {
  errorIfNotOnDesktop()
  return invoke<MatchingFile[]>("get_children_matching", { request })
}

async function createNewWindow() {
  errorIfNotOnDesktop()
  return invoke("create_new_window")
}

async function postDatagramBuf(msg: Uint8Array) {
  errorIfNotOnDesktop()
  return invoke("post_datagram_buf", msg)
}

async function addTransferCallback(callback: (base64string: string) => void) {
  errorIfNotOnDesktop()
  await listen<string>("transfer-message", ({ payload }) => callback(payload))
}

/**
 * TODO: This currently still uses JSON to send messages
 * back and forth
 * @param callback The method that receives messages from the cache
 */
async function addCacheCallback(callback: (base64string: string) => void) {
  errorIfNotOnDesktop()
  await listen<string>("cache-message", ({ payload }) => callback(payload))
}

/** @returns un-listen function to deregister listen event */
async function registerDragAndDropEvent() {
  errorIfNotOnDesktop()
  return await listen<{ paths: string[] }>(TauriEvent.DROP, event => onFileDropped(event))
}

async function onFileDropped(event: Event<{ paths: string[] }>) {
  const { myself } = useUser()
  const { transferState } = useTransferManager()
  const { navigationState } = useNavigation()

  const userId = myself.value.user?.userId
  if (!userId)
    throw new Error("No user for upload")
  const destination = navigationState.value.file as InterfaceFolderFile
  if (!destination || !destination.isFolder)
    throw new Error("No folder to upload to")

  const jobId = generateWorkerRequestId()
  transferState.value.jobs[jobId] = {
    type: "upload",
    id: jobId,
    timestamp: Date.now(),
    status: "calculating",
    destination,
    sources: {},
    total: 0,
    inProgress: 0,
    completed: 0,
    failed: 0,
  }
  const paths = event.payload.paths
  const folderId = destination._file.fileId
  return invoke("upload_items", { paths, userId, folderId, jobId })
}

async function downloadFiles(files: InterfaceFile[]) {
  const { myself } = useUser()
  const userId = myself.value.user?.userId
  if (!userId)
    throw new Error("No user for upload")
  return invoke("download_items", {
    jobId: generateWorkerRequestId(),
    items: files.map(f => f._file),
  })
}

async function copyFiles(files: InterfaceFile[]) {
  const { myself } = useUser()
  const userId = myself.value.user?.userId
  if (!userId)
    throw new Error("No user for upload")
  return invoke("copy_items", {
    jobId: generateWorkerRequestId(),
    items: files.map(f => f._file),
  })
}

export default function () {
  tauriState = useState("tauri-data", () => ({
    isWindowMaximized: false,
    handlersInitialized: false,
  }))

  return {
    tauriState,
    initTauriHandlers,
    minimizeWindow,
    maximizeWindow,
    unmaximizeWindow,
    toggleFullscreenWindow,
    closeWindow,
    login,
    signup,
    verify,
    resendVerification,
    forgotPassword,
    changePassword,
    getRecentSearches,
    getSearchTermMatching,
    getTagProperties,
    getTagsMatching,
    getFilesMatching,
    getFoldersMatching,
    getChildrenMatching,
    postDatagramBuf,
    addCacheCallback,
    addTransferCallback,
    registerDragAndDropEvent,
    createNewWindow,
    downloadFiles,
    copyFiles,
  }
}
